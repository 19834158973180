/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const SectionAbout = ({ storeCurrentBoba }) => (
	<>
		<section id="about">
			{storeCurrentBoba !== null ? (
				JSON.stringify(storeCurrentBoba.content) !== 'null' ? (
					<>
						<div className="about-content">
							<div className="about-content-wrapper">
								<div className="section-title-mobile">
									<p className="title-about boba-primary-font-body-xlg-bold">
										{storeCurrentBoba.content.title.substr(0, 5)}
									</p>
									<h2 className="title-boba-invert boba-primary-font-h2">
										{storeCurrentBoba.content.title.substr(6, 8)}
									</h2>
									<h2 className="title-boba boba-primary-font-h2">
										{storeCurrentBoba.content.title.substr(14, 14)}
										<span className="title-boba-red">
											{storeCurrentBoba.content.title.substr(27, 28)}
										</span>
									</h2>
								</div>
								<div className="section-title-desktop">
									<p className="title-about boba-primary-font-body-xlg-bold">
										{storeCurrentBoba.content.title.substr(0, 5)}
									</p>
									<h2 className="title-boba-invert boba-primary-font-h1">
										{storeCurrentBoba.content.title.substr(6, 8)}
									</h2>
									<h2 className="title-boba boba-primary-font-h1">
										{storeCurrentBoba.content.title.substr(14, 14)}
										<span className="title-boba-red">
											{storeCurrentBoba.content.title.substr(27, 28)}
										</span>
									</h2>
								</div>
								<div
									className="section-text boba-primary-font-body-lg-regular"
									dangerouslySetInnerHTML={{
										__html: storeCurrentBoba.content.description,
									}}
								/>
								<a
									id="btn_check_winners"
									href="/winners"
									className="btn blue from-center"
								>
									PAST CHAMPIONS
								</a>
							</div>
						</div>
					</>
				) : null
			) : null}
		</section>
		<style jsx>
			{`
				#about {
					background-image: url("/images/about_img_desktop.jpg");
					overflow: hidden;
					background-repeat: no-repeat;
					background-size: cover;
					width: 100%;
					min-height:75vh;
					height: 100%;
					display:flex;
					flex-direction: row;
				}
				img {
					width: 100%;
				}
				#about .image-title {
					width: 60%;
					margin-top: -7px;
					margin-bottom: -20px;
				}

				.about-content {
					//position: absolute;
					transform: translatex(60%);
					left: 50%;
					max-width: 62%;
				}

				.about-content-wrapper {
					padding: 112px 56.5px;
				}

				.about-content .title-boba {
					color: var(--boba-color-4);
					max-width: 560px;
				}

				.about-content .title-boba-invert {
					color: var(--fd-neutral-1);
					-webkit-text-stroke-width: 1px;
					-webkit-text-stroke-color: var(--boba-color-4);
					max-width: 560px;
				}

				.about-content .title-boba-red {
					color: var(--boba-color-2);
					max-width: 560px;
				}

				.about-content .section-title-mobile{
					display: none;
				}
				.about-content .title-about {
					color: var(--boba-color-4);
					margin-bottom: 10px;
				}

				.about-content .section-text {
					color: var(--boba-color-4);
					margin-bottom: 27px;
					margin-top: 16px;
				}

				.btn {
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 30px;
					display: flex;
					align-items: center;
					text-align: center;
					letter-spacing: 0.03em;
					color: #000000;
					display: flex;
					height: 50px;
					text-transform: uppercase;
					justify-content: center;
					align-items: center;
					transition: all .3s;
					position: relative;
					overflow: hidden;
					z-index: 1;
				}

				.btn.blue {
					background-color: var(--boba-color-2);
					color: #FFFFFF;
				}
				.btn.blue:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: var(--boba-color-2);
					z-index: -2;
				}
				.btn.blue:before {
					content: '';
					position: absolute;
					top: 0;
					left: 50%;
					height: 100%;
					width: 0;
					height: 100%;
					background-color: #bc2d61;
					transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
					z-index: -1;
				}
				.btn.blue:hover {
					color: #fff;
				}
				.btn.blue:hover:before {
					left: 0;
					width: 100%;
				}
				.about-content .btn.blue {
					width: 240px;
				}

				@media (max-width: 1150px) {
					#about {
						// padding: 24px 0 50px 0;
						width: 100%;
					height: 100%;
					display:flex;
					flex-direction: column;
					}

					#about .image-title {
						width: 100%;
						margin-top: -3px;
					}

					img {
						width: 100%;
					}

					.about-content {
						margin: -65px 16px 0 16px;
						//position: relative;
						transform: unset;
						max-width: unset;
						top: 45px;
						right: unset;
						display: flex;
						justify-content: flex-end;
					}

					.about-content-wrapper {
						padding: 28px 15px 30px 28px;
						border-radius: 16px;
						max-width: 55%;
					}

					.about-content .title-boba {
						font-size: 70px;
						line-height: 80px;
						letter-spacing: 0.1em;
						margin-bottom: 20px;
					}

					.about-content .section-text {
						font-size: 14px;
						line-height: 24px;
						margin-bottom: 20px;
					}

					.about-content .btn {
						font-size: 16px;
						width: unset;
						height: 44px;
					}

					.section-title.underline::after {
						margin-top: 10px;
					}]
				}

				@media (max-width: 954px) {
					.about-content-wrapper {
						padding: 28px 15px 30px 28px;
						border-radius: 16px;
						max-width: 52%;
					}
				}
				@media (max-width: 865px){
					.about-content .title-boba {
						font-size: 55px;
						line-height: 80px;
						letter-spacing: 0.1em;
						margin-bottom: 20px;
					}
				}

				@media (max-width: 704px){
					#about{
						background-image:  url("/images/about_single.jpg");
						background-repeat: no-repeat;
						background-size:contain;
						background-position: center;
						background-position-y: top;
					}
					.about-content .title-about {
						margin:	0 auto;
					}
					.about-content-wrapper {
						padding: 28px 0 30px 0;
						border-radius: 16px;
						max-width: 100%;
					}
					.about-content .section-title-mobile{
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						align-content: center;
						margin-top: 350px;
						margin-bottom: 23px;
					}
					.about-content .section-title-desktop{
						display: none;
					}
					.about-content .title-boba {
						font-style: normal;
						font-weight: 400;
						font-size: 40px;
						line-height: 48.5px;
						text-align: center;
						letter-spacing: 0.03em;
						max-width: 60%;
						margin: 0 auto;
					}
					.about-content .title-about{
						font-size: 24px;
						line-height: 36px;
						margin-bottom: -9px;

					}
					.about-content .btn {
						font-size: 16px;
						width: 240px;
						height: 50px;
						margin: 0 auto;
					}
				}

				@media (max-width: 540px){
					#about{
						// background-image:  url('https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+background+about+mobile.png');
						// background-image:  url("/images/about-img-mobile-1.png");
						background-position: center;
						background-position-y: top;
						margin-top: -6px;
					}
					.about-content-wrapper {
						padding: 0 0 32px 0;
						border-radius: 16px;
						max-width: 500px;
						margin: 0 auto;
					}
					.about-content .section-title-mobile{
						margin-top: 300px;
						margin-bottom: 23px;
					}
					.about-content .title-boba {
						font-style: normal;
						font-weight: 400;
						font-size: 40px;
						line-height: 48.5px;
						text-align: center;
						letter-spacing: 0.03em;
						max-width:  65%;
						margin: 0 auto;
					}
					.about-content .title-about {
						margin: 3rem auto 0;
					}
					.about-content .section-text {
						font-weight: 400;
						font-size: 16px;
						line-height: 22px;
						text-align: justify;
						letter-spacing: 0.03em;
						color: #323942;
						margin-bottom: 20px;
					}
					.about-content .btn {
						font-size: 16px;
						width: 240px;
						height: 50px;
						margin: 0 auto;
					}
				}

				@media (max-width: 440px){
					.about-content .title-boba {
						font-size: 40px;
						max-width: 90%;
					}

					.about-content .section-title-mobile{
						margin-top: 340px;
					}
				}

			`}
		</style>
	</>
);

SectionAbout.propTypes = {
	storeCurrentBoba: PropTypes.arrayOf.isRequired,
};

export default SectionAbout;
