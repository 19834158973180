/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const SectionSponsor = ({ isMobile, storeCurrentBoba }) => (
	<>
		<section id="sponsor">
			{storeCurrentBoba !== null ? (
				JSON.stringify(storeCurrentBoba.content) !== 'null' ? (
					<>
						<div className="content-container">
							{storeCurrentBoba.content.sponsor.map((item) =>
								item.name === 'mobile' && isMobile ? (
									<img
										key="sponsor-mobile"
										src={item.image}
										className="section-image-full"
										alt="sponsor list"
									/>
								) : item.name === 'desktop' && !isMobile ? (
									<img
										key="sponsor-desktop"
										src={item.image}
										className="section-image-full"
										alt="sponsor list"
									/>
								) : null,
							)}
						</div>
					</>
				) : null
			) : null}
		</section>
		<style jsx>
			{`
				#sponsor .section-image-full {
					width: 100%;
				}
			`}
		</style>
	</>
);

SectionSponsor.propTypes = {
	storeCurrentBoba: PropTypes.arrayOf.isRequired,
	isMobile: PropTypes.bool.isRequired,
};

export default SectionSponsor;
