import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Mobile = () => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		// speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		customPaging: () => <div className="ft-slick__dots--mobile" />,
	};

	return (
		<>
			<section id="banner-fullpage">
				<div className="section-banner">
					<Slider {...settings}>
						{/* <img className="banner-fullpage-mobile" src="https://image.femaledaily.com/awards/boba-2023/2023+BOBA+hero+banner+slider+mobile+01a.png" alt="mainBanner" /> */}
						<img
							className="banner-fullpage-mobile"
							src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/boba+2024/boba_mobile_hd.jpg"
							alt="thanksBanner"
						/>
					</Slider>
					<style>
						{`
            #banner-fullpage .section-banner {
              width: 100%;
            }
            #banner-fullpage .section-banner .slick-initialized .slick-slide {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              // background-color: #F7C5C4;
            }
            #banner-fullpage .section-banner .slick-dots {
              position: absolute;
              bottom: 24px;
            }
            #banner-fullpage .slick-dots li.slick-active .ft-slick__dots--mobile {
              height: 6px;
              width: 24px;
              background-color: var(--boba-color-4);
              border-radius: 6px;
              position: relative;
              animation: scale-up-center 0.5s linear;
            }
            #banner-fullpage .slick-dots li {
              width: fit-content;
            }
            #banner-fullpage .slick-dots li .ft-slick__dots--mobile {
              height: 6px;
              width: 6px;
              background-color: #fff;
              border-radius: 6px;
              position: relative;
            }
          `}
					</style>
				</div>
			</section>
			<style>
				{`
        .banner-fullpage-mobile {
            width: 100vw;
            height: 100%;
            background-size: cover;
            background-position: center center;
            display: block;
          }
        `}
			</style>
		</>
	);
};

export default Mobile;
