/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { END } from 'redux-saga';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useDispatch } from "react-redux";
import { loadSelf } from '@fdn/profile_store';
import Env from 'Consts/env';
import cookie from 'cookie';
import Cookies from 'js-cookie';
import Layout from 'Containers/layout';
import CategoryList from 'Components/categoryList';
import Banner from 'Components/banner';
import SectionArticle from 'Containers/SectionArticle';
import SectionPanelist from 'Containers/SectionPanelist';
import SectionAbout from 'Containers/SectionAbout';
import SectionSponsor from 'Containers/SectionSponsor';
import SectionSchedule from 'Containers/SectionSchedule';
import { wrapper } from '@/Store/index';
import { loadCompleting } from '@/Store/completing/lib/actions';
import { loadArticleTagSlug } from '@/Store/article_slug/lib/actions';
import { loadCurrentBoba } from '@/Store/current_boba/lib/actions';
import { loadCategory } from '@/Store/category/lib/actions';

// let chatSocket;
// let fetchSocket;

const Index = (props) => {
	const data = props;

	// the result of datenow variable is "2024-05-15"
	// dateNow variable just only for data checking award.end_date
	const dateNow = new Date().toISOString().slice(0, 10);
	const dispatch = useDispatch();
	const getData = useSelector((state) => state);

	// const [chatValue, setChatValue] = useState("");
	// const [chatList, setChatList] = useState([]);

	const checkDataUser = (paramsURL) => {
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${paramsURL}`;
	};

	// const connectFetchSocket = () => {

	// 	fetchSocket = new WebSocket(process.env.NEXT_PUBLIC_WEBSOCKET_FETCH);
	// 	fetchSocket.onmessage = (e) => {

	// 		try {

	// 			const response = JSON.parse(e.data);

	// 			if (response.type === "fetch_message") {

	// 				setChatList(response.data.message);
	// 				document.getElementById("chat-list").scrollTop = document.getElementById("chat-list").scrollHeight;

	// 			}

	// 		} catch (err) {

	// 			console.log("error fetch socket ", err);

	// 		}

	// 	};

	// };

	// const connectChatSocket = () => {

	// 	if (Cookies.get("token")) {

	// 		chatSocket = new WebSocket(`${process.env.NEXT_PUBLIC_WEBSOCKET_CHAT}${Cookies.get("token")}`);

	// 	} else {

	// 		chatSocket = new WebSocket(process.env.NEXT_PUBLIC_WEBSOCKET_CHAT);

	// 	}

	// };

	// const connectMainSocket = () => {

	// 	connectFetchSocket();
	// 	fetchSocket.onopen = () => {

	// 		fetchSocket.send(JSON.stringify({
	// 			type: "fetch_message",
	// 		}));

	// 	};
	// 	fetchSocket.onmessage = (e) => {

	// 		const response = JSON.parse(e.data);

	// 		if (response.type === "fetch_message") {

	// 			setChatList(response.data.message);
	// 			document.getElementById("chat-list").scrollTop = document.getElementById("chat-list").scrollHeight;

	// 		}

	// 	};
	// 	connectChatSocket();

	// };

	useEffect(() => {
		if (data.storeCurrentBoba !== null) {
			const token = Cookies.get('token');
			dispatch(
				loadCategory({
					payload: {
						headers: Env.HEADERS,
						boba_id: data.storeCurrentBoba.award_id,
						Authorization: token || null,
					},
				}),
			);
		}

		// connectMainSocket();
	}, []);

	// const submitChat = (socket, message) => {

	// 	try {

	// 		if (chatValue.length) {

	// 			socket.send(JSON.stringify({
	// 				type: "message_chat",
	// 				data: {
	// 					message,
	// 				},
	// 			}));

	// 		}

	// 		// eslint-disable-next-line no-param-reassign
	// 		socket.onmessage = (e) => {

	// 			const response = JSON.parse(e.data);

	// 			if (response.type === "final_message") {

	// 				const dataChat = chatList;
	// 				dataChat.push({
	// 					username: data.getStoreSelf.data.username,
	// 					message: response.data.message,
	// 				});
	// 				setChatList(dataChat);
	// 				setChatValue("");
	// 				document.getElementById("chat-list").scrollTop = document.getElementById("chat-list").scrollHeight;

	// 			}

	// 		};

	// 	} catch (err) {

	// 		console.log("error submit chat", err);

	// 	}

	// };

	// const handleTypeChat = (e) => {

	// 	if (e.keyCode === 13) {

	// 		submitChat(chatSocket, e.target.value);

	// 	} else {

	// 		if (e.target.value.length <= 100) {

	// 			setChatValue(e.target.value);

	// 		}

	// 	}

	// };

	// useEffect(() => {

	// 	if (typeof chatSocket === "object") {

	// 		chatSocket.onmessage = (e) => {

	// 			const response = JSON.parse(e.data);

	// 			if (response.type === "final_message") {

	// 				const dataChat = chatList;
	// 				dataChat.push({
	// 					username: response.data.sender.post.username,
	// 					message: response.data.message,
	// 				});
	// 				setChatList([...chatList], dataChat);
	// 				document.getElementById("chat-list").scrollTop = document.getElementById("chat-list").scrollHeight;

	// 			}

	// 		};

	// 	}

	// });

	return (
		<>
			<Layout
				home
				title="Femaledaily"
				ismobile={data.isMobile}
				self={data.getStoreSelf}
			>
				<Banner isMobile={data.isMobile} />

				{/* <section id="banner-fullpage">
					<img className="banner-fullpage-desktop" src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+Main+Banner+Microsite+Desktop.png" alt="mainBanner" />
					<img className="banner-fullpage-mobile" src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+Main+Banner+Microsite+Mobile.png" alt="mainBanner" />
					<style>
						{`
						.banner-fullpage-desktop {
							width: 100%;
							background-size: cover;
							background-position: center center;
							display: flex;
							align-items: flex-end;
							justify-content: center;
						}
						.banner-fullpage-mobile{
							display:none;
						}

						@media (max-width: 767px) {
							.banner-fullpage-desktop{
								display:none;
							}
							.banner-fullpage-mobile{
								display:block;
							}
							.banner-fullpage-mobile {
								width: 100%;
							}
						}
					`}
					</style>
				</section> */}

				{/* {
					data.isMobile
						? <img src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+mini+banner+mobile.png" alt="live-stream-banner" style={{ display: "block", width: "100%" }} />
						: <img src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+mini+banner.png" alt="live-stream-banner" style={{ display: "block", width: "100%" }} />
				} */}
				{/* <section id="live-stream">
					<div className="content-container">
						<div className="iframe-wrapper">
							<iframe
								width="786"
								height="480"
								src={process.env.NEXT_PUBLIC_NODE_ENV !== "production" ? "https://20.detik.com/watch/livestreaming-sponsor/boba2022?smartautoplay=true&counterviews=true" : "https://20.detik.com/watch/livestreaming-sponsor/boba2022?smartautoplay=true&counterviews=true"}
								title="BoBA Live Stream"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
							<script src="https://cdn.detik.net.id/detikVideo/lib/jquery-1.11.2.min.js" />
							<script src="https://cdn.detik.net.id/libs/livecounter/detikLiveUserCounterResponse.js" />
						</div>
						<div className="live-chat-wrapper">
							<div className="live-chat" data-content="">
								<div id="chat-list" className="chat-list">
									{
										chatList.length
											? chatList.map((chat, index) => (
												<div className="chat-content" key={index}>
													<p className="username">{chat.username}</p>
													<p className="chat-text">{chat.message}</p>
												</div>
											))
											: <p className="no-chat-placeholder">It is empty here...</p>
									}
								</div>
								<div className="chat-input">
									{
										data.getStoreSelf.data.id
											? (
												<>
													<form onSubmit={(e) => e.preventDefault()}>
														<input id="chat-input" placeholder="Tulis pesan Anda" type="text" onKeyUp={(e) => handleTypeChat(e)} onChange={(e) => (e.target.value.length <= 100 ? setChatValue(e.target.value) : false)} value={chatValue} autoComplete="off" />
													</form>
													<img
														src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/icon-send.svg`}
														alt="send"
														className="icon-send-chat"
														onClick={() => submitChat(chatSocket, chatValue)}
														aria-hidden
													/>
												</>
											)
											: <a href={process.env.NEXT_PUBLIC_SSO_DOMAIN}>Click here to join the chat</a>
									}
								</div>
							</div>
						</div>
					</div>
					<style jsx>
						{`
							#live-stream {
								background-image: url(https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+Background+live+stream.png);
								background-size: cover;
								position: relative;
								padding: 71px 0 67px 0;
							}

							#live-stream:before {
								content: attr(data-content);
								position: absolute;
								width: 100%;
								height: 100%;
								top: 0;
								left: 0;
							}

							#live-stream .content-container {
								position: relative;
								display: flex;
								justify-content: space-between;
								flex-flow: row wrap;
							}

							#live-stream .iframe-wrapper {
								margin-right: 60px;
							}

							.live-chat-wrapper {
								width: 362px;
								flex-grow: 1;
							}

							.live-chat {
								background-color: #FFF;
								height: 480px;
							}

							.live-chat .chat-list {
								padding: 27px 22px;
								height: calc(100% - 50px);
								max-height: calc(100% - 50px);
								overflow-y: scroll;
								scroll-behavior: smooth;
							}

							.live-chat .chat-content {
								margin-bottom: 16px;
							}

							.live-chat .chat-content:nth-last-child(1) {
								margin-bottom: 0;
							}

							.live-chat .chat-content .username {
								font-family: Josefin Sans;
								font-weight: bold;
								font-size: 14px;
								line-height: 149%;
								letter-spacing: 0.1em;
								text-transform: uppercase;
								color: #612C37;
								word-break: break-word;
							}

							.live-chat .chat-content .chat-text {
								font-family: Roboto;
								font-style: normal;
								font-weight: normal;
								font-size: 14px;
								line-height: 24px;
								color: #000;
								word-break: break-word;
							}

							.live-chat .chat-input {
								position: relative;
								border-width: 1px 0 0 0;
								border-color: #612C37;
								border-style: solid;
							}

							.live-chat .chat-input input {
								font-family: 'Poppins';
								font-style: normal;
								font-weight: 400;
								font-size: 16px;
								line-height: 22px;
								letter-spacing: 0.03em;
								width: 100%;
								height: 50px;
								border: none;
								padding: 14px 45px 14px 15px;
							}

							.live-chat .chat-input input:focus-visible {
								outline: none;
							}

							.live-chat .chat-input .icon-send-chat {
								position: absolute;
								top: 50%;
								right: 14px;
								transform: translateY(-50%);
								cursor: pointer;
							}

							.live-chat .chat-input a {
								display: block;
								height: 50px;
								padding: 14px 15px;
								font-family: 'Poppins';
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 24px;
								letter-spacing: 0.03em;
								color: #919EAB;
								transition: all .3s ease;
							}

							.live-chat .chat-input a:hover {
								color: #EB7879;
							}

							@media (max-width: 1024px) {

								#live-stream .iframe-wrapper {
									max-width: 60%;
									margin-right: 40px;
								}

								#live-stream .iframe-wrapper iframe {
									max-width: 100%;
									height: 100%;
								}

								#live-stream .live-chat-wrapper {
									max-width: calc(40% - 40px);
								}

								.live-chat {
									height: 373px;
								}

							}

							@media (max-width: 991px) {

								#live-stream {
									padding: 26px 0;
								}

								#live-stream .content-container {
									display: block;
								}

								#live-stream .iframe-wrapper {
									margin-right: 0;
									margin-bottom: 15px;
									position: relative;
									overflow: hidden;
									width: 100%;
									padding-top: 70%;
								}

								#live-stream .iframe-wrapper iframe {
									position: absolute;
									top: 0;
									left: 0;
									bottom: 0;
									right: 0;
									width: 100%;
									height: 100%;
								}

								#live-stream .iframe-wrapper,
								#live-stream .live-chat-wrapper {
									width: 100%;
									max-width: unset;
								}

								.live-chat {
									max-height: calc(100vh - 420px);
								}

								.live-chat .chat-list {
									padding: 27px 15px;
								}

							}
						`}
					</style>
				</section> */}

				<SectionAbout
					isMobile={data.isMobile}
					storeCurrentBoba={data.storeCurrentBoba}
				/>

				<SectionSponsor
					isMobile={data.isMobile}
					storeCurrentBoba={data.storeCurrentBoba}
				/>

				<SectionSchedule
					isMobile={data.isMobile}
					storeCurrentBoba={data.storeCurrentBoba}
				/>

				{/* <section id="schedule-image">
					<img className="schedule-img desktop" alt="event schedule desktop" src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/event-detail-desktop.png" />
					<img className="schedule-img mobile" alt="event schedule mobile" src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/event-detail-mobile.png" />
					<style>
						{`
							#schedule {
							}

							.schedule-img {
								width: 100%;
								height: auto;
								display: block;
								vertical-align: bottom;
								vertical-align: top;
								vertical-align: middle;
							}

							.schedule-img.mobile {
								display: none;
							}

							@media (max-width: 767px) {
								.schedule-img.mobile {
									display: block;
								}

								.schedule-img.desktop {
									display: none;
								}
							}
						`}
					</style>
				</section> */}

				{Array.isArray(getData.storeCategory?.data) &&
					getData.storeCategory?.data?.length > 0 &&
					dateNow <= data.storeCurrentBoba?.award_end_date && (
						<CategoryList
							sectionTitle="Category"
							sectionDescription="This season, you can vote in these categories!"
							checkDataUser={checkDataUser}
							categories={getData.storeCategory?.data}
						/>
					)}

				<SectionPanelist
					isMobile={data.isMobile}
					storeCurrentBoba={data.storeCurrentBoba}
				/>

				<SectionArticle
					isMobile={data.isMobile}
					title="Article"
					storeArticle={data.storeArticle}
				/>

				{data.storeCurrentBoba !== null ? (
					JSON.stringify(data.storeCurrentBoba.content) !== 'null' &&
					data.storeCurrentBoba.content.floating_image !== null &&
					dateNow <= data.storeCurrentBoba?.award_end_date ? (
						<>
							<div className="floating-button">
								<a
									id="btn_votenow"
									href={
										data.storeCurrentBoba.content.floating_image_link_direction
									}
								>
									<img
										src={data.storeCurrentBoba.content.floating_image}
										alt="floating-button"
									/>
								</a>
								<style jsx>
									{`
										.floating-button {
											position: fixed;
											bottom: 45px;
											right: 48px;
											z-index: 10;
										}

										.floating-button a {
											display: block;
										}

										.floating-button img {
											width: 100%;
											height: 82px;
										}

										@media (max-width: 767px) {
											.floating-button {
												bottom: 12px;
												right: 10px;
											}

											.floating-button img {
												width: 100%;
												height: 76px;
											}
										}
									`}
								</style>
							</div>
						</>
					) : null
				) : null}
			</Layout>
		</>
	);
};

export const getServerSideProps = wrapper.getServerSideProps(
	async ({ store, req, query }) => {
		let userAgent;
		let cookies;
		let authorization = null;
		let usrname = null;

		if (req) {
			// if you are on the server and you get a "req" property from your context
			userAgent = req.headers['user-agent']; // get the user-agent from the headers
		} else {
			// if you are on the client you can access the navigator from the window object
			userAgent = navigator.userAgent;
		}

		const isMobile = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
			),
		);

		const isServer = !!req;

		if (req.headers.cookie) {
			cookies = cookie.parse(req.headers.cookie);
			authorization = cookies.token;
			usrname = cookies.usrname;
		}

		if (usrname && authorization) {
			await store.dispatch(
				loadSelf({
					payload: {
						api_env: process.env.NEXT_PUBLIC_NODE_ENV,
						env: Env.HEADERS,
						Authorization: authorization,
						is_server: isServer,
						is_allo: process.env.NEXT_PUBLIC_ALLO_STATUS === 'ON',
					},
				}),
			);
		}

		await store.dispatch(
			loadCurrentBoba({
				payload: {
					headers: Env.HEADERS,
				},
			}),
		);

		let predefinedFinal;
		if (typeof cookies !== 'undefined' && cookies.fdpredefined) {
			const predefinedLocal = JSON.parse(cookies.fdpredefined);
			predefinedFinal = predefinedLocal;
		} else {
			const options = {
				method: 'GET',
				headers: {
					...Env.HEADERS,
				},
			};
			const resData = await fetch(
				`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/predefined`,
				options,
			);
			const res = await resData.json();
			const predefined = res.data;
			predefinedFinal = {
				city: predefined.city,
				skin_type: predefined.skin_type.map((item) => ({
					value: item.skityp_id,
					label: item.skityp_name,
				})),
				skin_tone: predefined.skin_tone.map((item) => ({
					value: item.skiton_id,
					label: item.skiton_name,
				})),
				skin_undertone: predefined.skin_undertone.map((item) => ({
					value: item.skiund_id,
					label: item.skiund_name,
				})),
				hair_type: predefined.hair_type.map((item) => ({
					value: item.haityp_id,
					label: item.haityp_name,
				})),
				hair_texture: predefined.hair_texture.map((item) => ({
					value: item.haitex_id,
					label: item.haitex_name,
				})),
				hair_color: predefined.hair_color.map((item) => ({
					value: item.id,
					label: item.value,
				})),
				is_hijab: predefined.is_hijab.map((item) => ({
					value: item.id,
					label: item.value,
				})),
				skin_concerns: predefined.skin_concerns.map((item) => ({
					value: item.skicon_id,
					label: item.skicon_name,
				})),
				body_concerns: predefined.body_concerns.map((item) => ({
					value: item.bodcon_id,
					label: item.bodcon_name,
				})),
				hair_concerns: predefined.hair_concerns.map((item) => ({
					value: item.haicon_id,
					label: item.haicon_name,
				})),
			};
		}

		await store.dispatch(
			loadCompleting({
				payload: {
					api_env: process.env.NEXT_PUBLIC_NODE_ENV,
					headers: Env.HEADERS,
					usrname,
					Authorization: authorization,
				},
			}),
		);

		await store.dispatch(
			loadArticleTagSlug({
				payload: {
					headers: Env.HEADERS,
					slug: process.env.NEXT_PUBLIC_XBEAUTY_ARTICLE_TAG,
				},
			}),
		);

		store.dispatch(END);
		await store.sagaTask.toPromise();

		const getStoreSelf =
			!!store.getState().storeSelf.data === true
				? store.getState().storeSelf
				: [];

		const storeCurrentBoba =
			!!store.getState().storeCurrentBoba.data === true &&
			!!store.getState().storeCurrentBoba.data.data === true &&
			store.getState().storeCurrentBoba.data.data.length
				? store.getState().storeCurrentBoba.data.data[0]
				: null;

		const storeArticle = Array.isArray(
			store.getState().storeArticleTagSlug.data.data,
		)
			? store.getState().storeArticleTagSlug.data.data
			: [];

		return {
			props: {
				query,
				isMobile,
				getStoreSelf,
				storeArticle,
				predefinedFinal,
				storeCurrentBoba,
			}, // will be passed to the page component as props
		};
	},
);

export default Index;
