/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

const SectionSchedule = ({ isMobile, storeCurrentBoba }) => (
	<section id="schedule">
		{storeCurrentBoba !== null ? (
			JSON.stringify(storeCurrentBoba.content) !== 'null' ? (
				<>
					<div className="schedule-wrapper">
						<div className="schedule-outer">
							<div className="schedule-content">
								<div className="image-hidden">
									<img
										className="schedule-image"
										src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/boba+2024/time_boba.jpg"
										alt="imageschedule"
									/>
								</div>
								<div className="section-text">
									<p
										className={`section-title ${isMobile ? 'boba-primary-font-h4' : 'boba-primary-font-h2'}`}
									>
										BoBA TIMETABLE
									</p>
									<ul id="schedule-list">
										{storeCurrentBoba.content.schedule.map((item, id) => (
											<li key={`schedule-${id + 1}`}>
												<h4 className="schedule-title boba-primary-font-h4">
													{item.name}
												</h4>
												<p className="schedule-date boba-primary-font-body-lg-regular">
													{item.date}
												</p>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null
		) : null}
		<style jsx>
			{`
				#schedule {
					background: url('https://image.femaledaily.com/awards/boba-2023/2023+BOBA+background+schedule+desktop-1.png'),
						var(--fd-neutral-1);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}

				#schedule .schedule-wrapper {
					max-width: 1280px;
					padding: 0 15px;
					margin: 0 auto;
				}
				#schedule .schedule-outer {
					padding: 30px 0;
				}
				.schedule-content {
					padding: 80px 180px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: var(--fd-neutral-1);
				}

				.schedule-image {
					width: 400px;
					height: 460px;
				}

				.schedule-content .section-title {
					color: var(--fd-neutral-10);
					margin-left: -44px;
				}

				#schedule-list {
					display: flex;
					padding: 0;
					margin: 0;
					flex-direction: column;
					width: 100%;
					max-width: 400px;
				}

				#schedule-list li {
					position: relative;
					min-width: 25%;
					max-width: 100%;
					padding-top: 25px;
				}

				#schedule-list li:nth-last-child(1) {
					padding-right: 0;
				}

				#schedule-list li::before {
					content: '';
					width: 16px;
					height: 16px;
					background-color: var(--boba-color-1);
					border-radius: 50%;
					position: absolute;
					top: 44%;
					left: -47px;
				}

				#schedule-list li::after {
					content: '';
					/* width: 2px; */
					height: 60%;
					position: absolute;
					top: 50%;
					left: -40px;
					border: 1px dashed var(--fd-neutral-10);
					margin: 20px 0;
				}
				#schedule-list li:nth-last-child(1)::after {
					display: none;
				}

				#schedule-list .schedule-title {
					color: var(--fd-neutral-10);
				}

				#schedule-list .schedule-date {
					color: var(--fd-neutral-10);
				}
				@media (max-width: 1140px) {
					#schedule-list {
						max-width: 52%;
					}
				}

				@media (max-width: 1024px) {
					#schedule-list {
						max-width: 50%;
					}
				}

				@media (max-width: 991px) {
					#schedule .image-title {
						width: 100%;
					}

					.schedule-content {
						padding: 34px 24px 46px 24px;
						display: flex;
						gap: 30px;
					}

					#schedule-list {
						// padding-left: 39px;
						// padding-right: 0;
						margin-top: 21px;
						display: block;
					}

					#schedule-list li {
						padding: 0 0 40px 0;
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						position: relative;
					}

					#schedule-list li::before {
						content: '';
						width: 14px;
						height: 14px;
						border-radius: 50%;
						position: absolute;
						top: 0;
						left: -25px;
					}

					#schedule-list li::after {
						content: '';
						height: 80%;
						position: absolute;
						top: 0;
						left: -20px;
					}

					#schedule-list li:nth-last-child(1) {
						padding-bottom: 0;
					}

					#schedule-list .schedule-title {
						font-size: 16px;
						line-height: 149%;
						margin-bottom: 8px;
					}

					#schedule-list .schedule-date {
						font-size: 14px;
						line-height: 18px;
					}
				}
				@media (max-width: 540px) {
					#schedule {
						background: url('https://image.femaledaily.com/awards/boba-2023/2023+BOBA+background+schedule+mobile-1.png'),
							var(--fd-neutral-1);
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
					}
					.schedule-content {
						padding: 34px 0 46px 0;
						flex-direction: column;
						align-items: center;
						height: 100%;
					}
					.schedule-content .section-title {
						color: var(--fd-neutral-10);
						margin-left: 0;
						text-align: center;
					}
					.image-hidden {
						display: none;
					}
					#schedule-list {
						max-width: 100%;
						padding-left: 34px;
						margin-top: 32px;
						padding-bottom: 16px;
						width: 100%;
					}

					#schedule-list li {
						padding-bottom: 16px;
					}
					#schedule-list .schedule-title {
						font-size: 20px;
						line-height: 28px;
					}
					#schedule-list .schedule-date {
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--fd-neutral-10);
					}
					#schedule-list li::after {
						left: -19px;
						margin-top: 23px;
						height: 72%;
					}
					#schedule-list li::before {
						top: 5px;
						left: -25px;
					}
				}
			`}
		</style>
	</section>
);

SectionSchedule.propTypes = {
	storeCurrentBoba: PropTypes.arrayOf.isRequired,
	isMobile: PropTypes.bool.isRequired,
};

export default SectionSchedule;
