import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Desktop = () => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		// speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		customPaging: () => <div className="ft-slick__dots--custom" />,
	};

	return (
		<>
			<section id="banner-fullpage">
				<div className="section-banner">
					<Slider {...settings}>
						{/* <div className="banner">
							<img className="banner-fullpage-desktop" src="https://image.femaledaily.com/awards/boba-2023/2023+BOBA+hero+banner+slider+desktop+01b.png" alt="mainBanner" />
						</div> */}
						<div className="banner">
							<img
								className="banner-fullpage-desktop"
								src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/boba+2024/boba_super_hd_desktop.jpg"
								alt="thanksBanner"
							/>
						</div>
					</Slider>
				</div>
				<style>
					{`
            #banner-fullpage .section-banner {
              height: 100%;
            }
            #banner-fullpage .section-banner .slick-initialized .slick-slide {
              display: flex;
              align-items: center;
              justify-content: center;
              // background-color: #F7C5C4;
            }
            #banner-fullpage .section-banner .slick-dots {
              position: absolute;
              bottom: 36px;
            }
            #banner-fullpage .slick-dots li.slick-active .ft-slick__dots--custom {
              height: 6px;
              width: 24px;
              background-color: var(--boba-color-4);
              border-radius: 6px;
              position: relative;
              animation: scale-up-center 0.5s linear;
            }
            #banner-fullpage .slick-dots li .ft-slick__dots--custom {
              height: 6px;
              width: 6px;
              background-color: #fff;
              border-radius: 6px;
              position: relative;
            }
          `}
				</style>
				<style>
					{`
          .banner-fullpage-desktop {
            width: 100vw;
            background-size: cover;
            background-position: center center;
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }

          @media (max-width: 767px) {
							.banner-fullpage-desktop{
								display:none;
							}
							.banner-fullpage-mobile{
								display:block;
							}
							.banner-fullpage-mobile {
								width: 100%;
							}
						}
        `}
				</style>
			</section>
		</>
	);
};

export default Desktop;
