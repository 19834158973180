import PropTypes from 'prop-types';
import React from 'react';

const CategoryList = ({ ...props }) => (
	<>
		<section id="category">
			<div className="content-container">
				<div className="category-content">
					<div className="category-content-wrapper">
						{props.sectionTitle && (
							<h2 className="section-title boba-primary-font-h2 mb-5">
								{props.sectionTitle}
							</h2>
						)}
						{props.sectionDescription && (
							<p className="section-subtitle boba-primary-font-body-lg-medium">
								{props.sectionDescription}
							</p>
						)}
						{Array.isArray(props.categories) ? (
							<div className="category-list">
								{props.categories.map((category, id) => (
									<div
										className={`category-item no-select${category.has_voted ? ' checked' : ''}`}
										key={`category-${category.category_name}`}
									>
										{typeof props.checkDataUser === 'function' ? (
											<>
												{category.has_voted && (
													<div className="category-checkmark">
														<img
															src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/icon-checkmark.svg`}
															alt="checkmark"
														/>
													</div>
												)}
												<button
													key={`category-${id + 1}`}
													id={`id_cat_${category.category_slug.toLowerCase()}`}
													onClick={
														typeof props.checkDataUser === 'function'
															? () =>
																	props.checkDataUser(
																		`category/vote/${category.category_id}`,
																	)
															: () => false
													}
													aria-hidden="true"
													type="button"
												>
													{JSON.stringify(category.category_img) !== 'null' &&
													category.category_img !== '' &&
													typeof category.category_img !== 'undefined' ? (
														<>
															<img
																className="animation-hover"
																src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+star.png.svg"
																alt="blink-hover"
															/>
															<div className="section-image">
																<img
																	src={category.category_img}
																	alt={`${category.category_name} category`}
																	className="category-image"
																/>
															</div>
														</>
													) : (
														<>
															<img
																className="animation-hover"
																src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+star.png.svg"
																alt="blink-hover"
															/>
															<div className="section-image">
																<img
																	src="https://image.femaledaily.com/web-assets/boba-category-placeholder.svg"
																	alt={`${category.category_name} category`}
																	className="category-image"
																/>
															</div>
														</>
													)}
													<p className="boba-primary-font-body-lg-bold category-name">
														{category.category_name}
													</p>
												</button>
											</>
										) : (
											<>
												{category.has_voted && (
													<div className="category-checkmark">
														<img
															src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/icon-checkmark.svg`}
															alt="checkmark"
														/>
													</div>
												)}
												<a
													id={`id_cat_${category.category_slug.toLowerCase()}`}
													key={`category-${id + 1}`}
													href={`category/vote/${category.category_slug}`}
												>
													{JSON.stringify(category.category_img) !== 'null' &&
													category.category_img !== '' &&
													typeof category.category_img !== 'undefined' ? (
														<>
															<img
																className="animation-hover"
																src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+star.png.svg"
																alt="blink-hover"
															/>
															<div className="section-image">
																<img
																	src={category.category_img}
																	alt={`${category.category_name} category`}
																	className="category-image"
																/>
															</div>
														</>
													) : (
														<>
															<img
																className="animation-hover"
																src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/awards/FD+BoBA+2022+-+star.png.svg"
																alt="blink-hover"
															/>
															<div className="section-image">
																<img
																	src="https://image.femaledaily.com/web-assets/boba-category-placeholder.svg"
																	alt={`${category.category_name} category`}
																	className="category-image"
																/>
															</div>
														</>
													)}
													<p className="boba-primary-font-body-lg-bold category-name">
														{category.category_name}
													</p>
												</a>
											</>
										)}
									</div>
								))}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</section>
		<style>
			{`
			#category {
				padding: 80px 0;
			}

			.category-content {
				text-align: center;
			}

			#category .section-title {
				// font-weight: 400;
			}

			#category .category-list {
				margin-top: 40px;
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				justify-content: center;
			}

			#category .category-item {
				width: 14.2857142857%;
				min-width: 14.2857142857%;
				position: relative;
				cursor: pointer;
				transition: all .2s ease;
				margin-bottom: 31px;
			}

			/* [1] The container */
			.section-image {
				overflow: hidden; /* [1.2] Hide the overflowing of child elements */
				border-radius: 50%;
				width: 110px;
				height: 110px;
				margin: 0 auto;
				margin-bottom: 30px;
			}
			#category .category-item .animation-hover{
				display: none;
			}

			#category .category-item:hover .animation-hover{
				display: block;
				position:absolute;
				z-index: 2;
				right: 30px;
				top: -20px;
				animation:  fadeInAnimation ease 1.2s;
				animation-iteration-count: 1;
				animation-fill-mode: forwards;
			}

			@keyframes fadeInAnimation {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}

			/* [2] Transition property for smooth transformation of images */
			.section-image.category-image {
				-webkit-transition: 0.5s ease;
				transition: transform .5s ease;
			}

			/* [3] Finally, transforming the image when container gets hovered */
			.section-image:hover .category-image {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
				transition: transform .5s ease;

			}

			#category .category-item.checked {
				pointer-events: none;
			}

			#category .category-item:nth-last-child(1),
			#category .category-item:nth-last-child(2) {
				margin-bottom: 0;
			}

			#category .category-item button {
				border: none;
				background: transparent;
				cursor: pointer;
			}

			#category .category-item .category-image,
			#category .category-checkmark {
				width: 112px;
				height: 112px;
				border-radius: 50%;
				display: block;
				overflow: hidden;
			}

			#category .category-item .category-image {
				// border: 1px solid #000;
				margin-left: auto;
				margin-right: auto;
			}

			#category .category-item .category-name {
				display: flex;
				align-items: center;
				text-align: center;
				letter-spacing: 0.03em;
				color: #323942;
				justify-content: center;
				text-transform: uppercase;
				word-wrap: break-word;
				word-break: break-word;
			}

			#category .category-checkmark {
				position: absolute;
				background-color: #00000060;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 50%;
				transform: translateX(-50%);
				margin-right: -50%;
			}

			@media (max-width: 991px) {
				#category {
					padding: 0 16px;
				}

				.category-content {
					padding: 23px 0 20px 0;
					border-radius: 16px;
				}
				/* [1] The container */
				.section-image {
					width: 84.14px;
					height: 84px;
					margin-bottom: 20px;
				}

				#category .category-list {
					margin-top: 24px;
					justify-content: space-around;
				}

				#category .category-item {
					width: 50%;
					min-width: 50%;
					margin-bottom: 31px;
				}

				#category .category-item:hover .animation-hover{
					display: none;
				}

				#category .category-item .category-name {
					font-size: 14px;
					line-height: 22px;
				}
				#category .category-item .category-image,
				#category .category-checkmark {
					width: 84.14px;
					height: 84px;
				}
			}
		`}
		</style>
	</>
);

export default CategoryList;

CategoryList.propTypes = {
	checkDataUser: PropTypes.func,
	sectionTitle: PropTypes.string,
	sectionDescription: PropTypes.string,
	categories: PropTypes.instanceOf(Array),
};

CategoryList.defaultProps = {
	checkDataUser: () => false,
	sectionTitle: 'CATEGORY',
	sectionDescription: null,
	categories: [],
};
