import React from 'react';
import ArticleCard from '@fdn/article_card';
import { momentDate } from 'Helpers/utils';
import PropTypes from 'prop-types';

const SectionArticle = ({ isMobile, title, storeArticle }) => (
	<section id="article">
		<div className="content-container">
			{isMobile ? (
				<a
					href={`${process.env.NEXT_PUBLIC_EDITORIAL_DOMAIN}/blog/tag/${process.env.NEXT_PUBLIC_XBEAUTY_ARTICLE_TAG}`}
					className="section-title-url"
					id="btn_more_articles"
				>
					<h2 className="boba-primary-font-h4 section-title">{title}</h2>
				</a>
			) : (
				<h2 className="boba-primary-font-h2 section-title mb-8">{title}</h2>
			)}
			<div className="article-list">
				{typeof storeArticle !== 'undefined' && storeArticle.length
					? storeArticle.map((item) => (
							<div
								className="article-item-wrapper"
								key={`id-article-${item.id}`}
							>
								<ArticleCard
									is_mobile={isMobile}
									article_link={item.url}
									article_image={item.image}
									article_title={item.title}
									article_author_name={item.author.name}
									article_date={momentDate({ date: item.created_at })}
								/>
							</div>
						))
					: null}
			</div>

			<div className="btn-see-more-wrapper">
				<a
					className="btn-see-more"
					href={`${process.env.NEXT_PUBLIC_EDITORIAL_DOMAIN}/blog/tag/${process.env.NEXT_PUBLIC_XBEAUTY_ARTICLE_TAG}`}
					id="btn_more_articles"
				>
					<p>More Articles</p>
					<span className="fdicon-arrow-right" />
				</a>
			</div>

			<style>
				{`
          
          #article {
            margin-top: -75px;
            padding-top: 75px;
          }
          #article .content-container {
            padding: 60px 15px 60px 15px;
            overflow: hidden;
          }

          #article .article-list {
            display: flex;
            justify-content: space-between;
          }

          #article .article-item-wrapper {
            max-width: 352px;
          }

          .article-item-wrapper .article-card-search-result {
            max-width: 352px;
            flex-wrap: wrap;
            flex-direction: column;
          }

          .article-item-wrapper .article-card-search-result .image-wrapper {
            width: 352px;
            aspect-ratio: 3 / 2;
            max-height: unset;
            max-width: unset;
            margin-bottom: 20px;
          }

          .article-item-wrapper .article-card-search-result .image-wrapper a img {
            max-height: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          
          .article-item-wrapper .article-card-search-result .detail-wrapper {
            width: unset;
            max-width: unset;
            margin-left: 0;
          }

          .article-item-wrapper .article-card-search-result .title a {
            color: var(--boba-color-4, #161415);
            font-family: REM;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            -webkit-line-clamp: 3;
            max-width: 375px;
          }

          .article-item-wrapper .article-card-search-result .detail p,
          .article-item-wrapper .article-card-search-result .detail p span {
            color: var(--boba-color-5, #DCDCDC);
            font-family: REM;
            font-size: 13.193px;
            font-style: normal;
            font-weight: 400;
            line-height: 18.847px;
          }

          .btn-see-more-wrapper {
            display: table;
            margin-top: ${isMobile ? '12px' : '70px'};
            margin-left: ${isMobile ? '16px' : 'auto'};
            margin-right: auto;
          }

          .btn-see-more {
            font-family: REM;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px;
            text-align: right;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset 0px -1px 0px #000000;
            text-transform: uppercase;
          }

          .btn-see-more:hover {
            color: #7E303D;
            box-shadow: inset 0px -1px 0px #7E303D;
          }

          .btn-see-more p {
            margin-bottom: 8px;
          }

          .btn-see-more span {
            padding-left: 10px;								
            font-size: 24px;
          }
          
          @media (max-width: 1180px){
            #article .content-container{
              padding: 24px 20px 38px 20px;
              overflow: hidden;
            }
            .article-item-wrapper .article-card-search-result .image-wrapper {
              width: 352px;
              aspect-ratio: 3 / 2;
            }

          }
          @media (max-width: 1115px){
            #article .content-container{
              padding: 24px 20px 38px 20px;
              overflow: hidden;
            }
            .article-item-wrapper .article-card-search-result .image-wrapper {
              width: 300px;
              aspect-ratio: 3 / 2;
            }

          }

          @media (max-width: 1024px) {
            .article-item-wrapper .article-card-search-result .image-wrapper {
              width: 300px;
              aspect-ratio: 3 / 2;
            }
          }
          
          @media (max-width: 991px) {
            #article{
              margin-top: -70px;
              padding-top: 70px;
            }
            #article .content-container{
              padding: 24px 0 38px 0;
              overflow: hidden;
            }

            #article .section-title-url {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #000;
              margin: 0 16px;
            }

            #article .section-title {
              font-size: 24px;
              font-weight: 400;
              line-height: 32px;
              margin-bottom: 12px;
            }

            #article .article-item-wrapper:nth-child(1) {
              margin-left: 16px;
            }

            #article .section-title-icon {
              font-size: 40px;
              line-height: 40px;
            }

            #article .article-list {
              display: flex;
              flex-wrap: no-wrap;
              overflow-x: scroll;
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            }

            #article .article-list::-webkit-scrollbar {
              display: none;
            }

            #article .article-item-wrapper {
              margin-right: 16px;
              width: unset;
              min-width: unset;
            }

            .article-item-wrapper .article-card-search-result .image-wrapper {
              width: 240px;
              aspect-ratio: 3 / 2;
              margin-bottom: 20px;
            }
            
            .article-item-wrapper .article-card-search-result .detail-wrapper {
              margin-left: 0;
            }

            .article-item-wrapper .article-card-search-result .detail p,
            .article-item-wrapper .article-card-search-result .detail p span {
              font-size: 14px;
              line-height: 24px;
            }
          }							
        `}
			</style>
		</div>
	</section>
);

SectionArticle.propTypes = {
	isMobile: PropTypes.bool,
	title: PropTypes.string,
	storeArticle: PropTypes.arrayOf.isRequired,
};

SectionArticle.defaultProps = {
	isMobile: false,
	title: 'Article',
};

export default SectionArticle;
