import PropTypes from 'prop-types';
import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Banner = ({ isMobile }) => {
	if (isMobile) {
		return <Mobile />;
	}

	return <Desktop />;
};

export default Banner;

Banner.propTypes = {
	isMobile: PropTypes.bool,
};

Banner.defaultProps = {
	isMobile: null,
};
