/* eslint-disable no-nested-ternary */
import Panelist from 'Components/panelist';
import React from 'react';
import PropTypes from 'prop-types';

const SectionPanelist = ({ isMobile, storeCurrentBoba }) => (
	<section id="panelist">
		{storeCurrentBoba !== null ? (
			JSON.stringify(storeCurrentBoba.content) !== 'null' ? (
				<div className="content-container">
					<Panelist
						isMobile={isMobile}
						panelist={storeCurrentBoba.content.panelist}
					/>
				</div>
			) : null
		) : null}
		<style jsx>
			{`
				#panelist {
					background-color: var(--boba-color-1);
					padding: 80px 0;
				}
				@media (max-width: 1190px) {
					#panelist {
						padding: 80px 24px;
						overflow-x: visible;
					}
				}
				@media (max-width: 767px) {
					#panelist {
						background-color: var(--boba-color-1);
						padding: 24px 0;
						overflow-x: visible;
					}

					#panelist .content-container {
						padding: 0;
					}
				}
			`}
		</style>
	</section>
);

SectionPanelist.propTypes = {
	isMobile: PropTypes.bool,
	storeCurrentBoba: PropTypes.arrayOf.isRequired,
};

SectionPanelist.defaultProps = {
	isMobile: false,
};

export default SectionPanelist;
